import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"

export default ({ children }) => (
  <div
    css={css`
      margin: 0 auto;
      max-width: 700px;
      padding: ${rhythm(2)};
      padding-top: ${rhythm(1.5)};
    `}
  >
    <div
     css={css`
       display: flex;
       justify-content: space-between;
       margin-bottom: ${rhythm(2)};
     `}
    >
      <Link to={`/`}>
        <h3
          css={css`
            margin-top: 0;
            font-style: normal;
          `}
        >
          Pandas Eating Lots
        </h3>
      </Link>
      <Link
        to={`/about/`}
      >
        About
      </Link>
    </div>
    {children}
  </div>
)